import {
  Center,
  Container,
  Flex,
  Grid,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectBox,
  SelectImage,
} from "../../../../../utils/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductCard } from "../../../../../components/ProductCard";
import { IoMdAdd } from "react-icons/io";
import CustomModal from "../../../../../components/CustomModal";
import { Table1 } from "../../../../../components/Compnent";
import { TableAddProd } from "../../../../../components/TableAddProd";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import CustomPopup from "../../../../../components/CustomPopup";
import { toast } from "react-toastify";
import store from "../../../../../redux/store";
import {
  get_consumable_list,
  get_consumable_product_list,
} from "../../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const SuppliersMain = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { state } = useLocation();

  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const obj = { sub_cat_id: state?.id, userid: user?.id };

  const { consumable_list, consumable_product_list, consumable_loading } =
    useSelector((state) => state.allData);


  useEffect(() => {
    store.dispatch(get_consumable_product_list(state?.id));
    store.dispatch(get_consumable_list(obj));
  }, []);

  const validate = () => {
   
    if (
      !data?.product_cat ||
      (data?.product_cat && data?.product_cat?.length == 0) ||
      !data?.name ||
      (data?.name && data?.name?.length == 0) ||
      !data?.brand ||
      (data?.brand && data?.brand?.length == 0) ||
      !data?.color ||
      (data?.color && data?.color?.length == 0) ||
      !data?.size ||
      (data?.size && data?.size <= 0) ||
      !data?.price ||
      (data?.price && data?.price <= 0) ||
      !data?.discount ||
      (data?.discount && data?.discount < 0) ||
      !data?.stock ||
      (data?.stock && data?.stock <= 0) ||
      !data?.min_qty ||
      (data?.min_qty && data?.min_qty < 0) ||
      !data?.min_del_day ||
      (data?.min_del_day && data?.min_del_day < 0) ||
      !data?.description ||
      (data?.description && data?.description?.length == 0) ||
      !data?.manu_date ||
      (data?.manu_date && data?.manu_date?.length != 10) ||
      (!data?.file?.name && !edit)
    ) {
      setData({
        ...data,
        product_catMess:
          !data?.product_cat ||
          (data?.product_cat && data?.product_cat?.length == 0)
            ? "Product Category is required"
            : "",
        nameMess:
          !data?.name || (data?.name && data?.name?.length == 0)
            ? "Name is required"
            : "",
        brandMess:
          !data?.brand || (data?.brand && data?.brand?.length == 0)
            ? "Brand is required"
            : "",
        colorMess:
          !data?.color || (data?.color && data?.color?.length == 0)
            ? "Color is required"
            : "",
        sizeMess: !data?.size
          ? "Size is required"
          : data?.size && data?.size <= 0
          ? "Size always greater then zero"
          : "",
        priceMess: !data?.price
          ? "Price is required"
          : data?.price && data?.price <= 0
          ? "Price always greater then zero"
          : "",
        discountMess:
          !data?.discount || (data?.discount && data?.discount < 0)
            ? "Discound is required"
            : "",
        stockMess: !data?.stock
          ? "Available Quantity is required"
          : data?.stock && data?.stock <= 0
          ? "Available Quantity must be greater then zero"
          : "",
        min_qtyMess: !data?.min_qty
          ? "Minimum Quantity is required"
          : data?.min_qty && data?.min_qty <= 0
          ? "Minimum Quantity must be greater then zero"
          : "",
        min_del_dayMess: !data?.min_del_day
          ? "Minimum days is required"
          : data?.min_del_day && data?.min_del_day <= 0
          ? "Minimum days must be greater then zero"
          : "",
        descriptionMess:
          !data?.description ||
          (data?.description && data?.description?.length == 0)
            ? "Description is required"
            : "",
        manu_dateMess: !data?.manu_date
          ? "Manufacturing Date is required"
          : data?.manu_date && data?.manu_date?.length != 10
          ? "Please Enter Correct Manufacturing Date"
          : "",
        fileMess: !data?.file?.name && !edit ? "Product Image is required" : "",
      });
      return false;
    }
    return true;
  };

  const add_items = async () => {
    const validatedata = validate();
    console.log("validatedata ===", validatedata);
    if (validatedata) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", edit ? "update" : "createshop");
        body.append("service_user_id", user?.id);
        body.append("service_id", 7);
        body.append("sub_category_id", state?.id);
        body.append("product_cat", data?.product_cat);
        body.append("product_name", data?.name);
        body.append("p_name", data?.name);
        body.append("price", data?.price);
        body.append("discount", data?.discount);
        body.append("quantity", data?.stock);
        body.append("min_quantity", data?.min_qty);
        body.append("manufacture_date", data?.manu_date); // add this field
        body.append("description", data?.description); // add this field
        body.append("max_day_deliver", data?.min_del_day);
        body.append("brand", data?.brand); // add this field
        body.append("color", data?.color); // add this field
        body.append("size", data?.size);
        body.append("image", data?.file || "");

        const responce = await fetch(
          process.env.REACT_APP_BASE_URL + "Lets_shop.php",
          { method: "POST", body: body }
        );
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          setData({});
          onClose();
          store.dispatch(get_consumable_list(obj));
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const delete_items = async () => {
    try {
      setLoading(true);
      // delete(service_id,sub_category_id,service_user_id,product_cat)
      console.log("state daadasda ===", state);
      const body = new FormData();
      body.append("action", "delete");
      body.append("service_id", 7);
      body.append("sub_category_id", state?.id);
      body.append("service_user_id", user?.id);
      body.append("product_cat", id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Lets_shop.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        onPopClose();
        store.dispatch(get_consumable_list(obj));
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state?.name}
          titlelist={[
            { name: "Home", to: "/" },
            { name: "Our Offering", to: "/home" },
            { name: "Consumables", to: "/let's-shop" },
          ]}
        />

        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            icon={<IoMdAdd size={20} color="#fff" />}
            title={"Add"}
            onClick={() => {
              onOpen();
              //   setData({ type: "create" });
            }}
          />
          <CustomButton
            title={"Orders"}
            bg={"brand.primary"}
            onClick={() => {
              navigate("/let's-shop/suplier-order", { state: state });
            }}
          />
        </Flex>
      </Flex>
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {consumable_loading ? (
          <Loader />
        ) : consumable_list?.length == 0 ? (
          <Center p={10}>
          <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
            No Data Found
          </Text>
        </Center>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {consumable_list?.map((item, index) => (
              <ProductCard
                key={index}
                min_order={item?.min_quantity}
                prod_name={item?.P_name}
                qunatity={item?.Quantity}
                price={item?.Price}
                Discount={item?.Discount}
                product_cat={
                  consumable_product_list?.filter(
                    (v) => v?.id == item?.product_cat
                  )?.[0]?.name
                }
                title={item?.name}
                url={item?.P_image}
                EditIcon={
                  <FaEdit
                    onClick={() => {
                      onOpen();
                      setEdit(true);
                      setData({
                        product_cat: item?.product_cat,
                        name: item?.P_name,
                        brand: item?.Brand,
                        color: item?.Color,
                        size: item?.Size,
                        price: item?.Price,
                        discount: item?.Discount,
                        stock: item?.Quantity,
                        min_qty: item?.min_quantity,
                        min_del_day: item?.Max_day_deliver,
                        description: item?.Description,
                        manu_date: moment(item?.Datetime).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                }
                DeleteIcon={
                  <MdDeleteForever
                    onClick={() => {
                      onPopOpen();
                      setId(item?.ID);
                    }}
                  />
                }
              />
            ))}
          </Grid>
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={"Add Product"}
      >
        <Container maxW={"container.md"} p={2}>
          <Table1>
            {/* <TableAddProd
              item={
                <InputBox
                  placeholder={"Product Id"}
                  value={data.prod_id}
                  disable={true}
                />
              }
              lable={"Product Id"}
            /> */}
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Category"}
                  value={"Consumable"}
                  disable={true}
                />
              }
              lable={"Category"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Sub Category"}
                  value={state?.name}
                  disable={true}
                />
              }
              lable={"Sub Category"}
            />
            <TableAddProd
              item={
                <SelectBox
                  value={data.product_cat}
                  onChange={(e) => {
                    setData({
                      ...data,
                      product_cat: e.target.value,
                      product_catMess: "",
                    });
                  }}
                  errorMess={data?.product_catMess}
                  options={
                    <>
                      <option value={""}>Select</option>
                      {/* <option value={"1"}>Office items</option>
                      <option value={"2"}>Statnary items</option>
                      <option value={"3"}>School uniforms</option>
                      <option value={"4"}>Mastic Flooring</option> */}
                      {consumable_product_list?.map((v, i) => (
                        <option key={i} value={v?.id}>
                          {v?.name}
                        </option>
                      ))}
                    </>
                  }
                />
              }
              lable={"Product Category"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Name"}
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value, nameMess: "" });
                  }}
                  errorMess={data?.nameMess}
                />
              }
              lable={"Name"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Brand"}
                  value={data.brand}
                  onChange={(e) => {
                    setData({ ...data, brand: e.target.value, brandMess: "" });
                  }}
                  errorMess={data?.brandMess}
                />
              }
              lable={"Brand"}
            />
            <TableAddProd
              item={
                <InputBox
                  type={"color"}
                  placeholder={"Color"}
                  value={data.color || "#2b3478"}
                  onChange={(e) => {
                    setData({ ...data, color: e.target.value, colorMess: "" });
                  }}
                  errorMess={data?.colorMess}
                />
              }
              lable={"Color"}
            />
            <TableAddProd
              item={
                <InputBox
                  type={"number"}
                  placeholder={"Size"}
                  onChange={(e) => {
                    setData({ ...data, size: e.target.value, sizeMess: "" });
                  }}
                  errorMess={data?.sizeMess}
                  value={data.size}
                />
              }
              lable={"Size"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Price"}
                  value={data.price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value, priceMess: "" });
                  }}
                  errorMess={data?.priceMess}
                />
              }
              lable={"Price"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Discount"}
                  value={data.discount}
                  onChange={(e) => {
                    setData({
                      ...data,
                      discount: e.target.value,
                      discountMess: "",
                    });
                  }}
                  errorMess={data?.discountMess}
                />
              }
              lable={"Discount"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Available Quantity"}
                  value={data.stock}
                  onChange={(e) => {
                    setData({ ...data, stock: e.target.value, stockMess: "" });
                  }}
                  errorMess={data?.stockMess}
                />
              }
              lable={"Available Quantity"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Minimum Order Qty"}
                  value={data.min_qty}
                  onChange={(e) => {
                    setData({
                      ...data,
                      min_qty: e.target.value,
                      min_qtyMess: "",
                    });
                  }}
                  errorMess={data?.min_qtyMess}
                />
              }
              lable={"Minimum Order Qty"}
            />
            <TableAddProd
              item={
                <InputBox
                  placeholder={"Minimum days to deliver "}
                  value={data.min_del_day}
                  onChange={(e) => {
                    setData({
                      ...data,
                      min_del_day: e.target.value,
                      min_del_dayMess: "",
                    });
                  }}
                  errorMess={data?.min_del_dayMess}
                />
              }
              lable={"Minimum days to deliver "}
            />
            <TableAddProd
              item={
                <InputBox
                  area={true}
                  placeholder={"Description "}
                  value={data.description}
                  onChange={(e) => {
                    setData({
                      ...data,
                      description: e.target.value,
                      descriptionMess: "",
                    });
                  }}
                  errorMess={data?.descriptionMess}
                />
              }
              lable={"Description "}
            />
            <TableAddProd
              item={
                <InputBox
                  type={"date"}
                  placeholder={"Manufacturing Date "}
                  value={data.manu_date}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input?.length <= 10) {
                      setData({
                        ...data,
                        manu_date: input,
                        manu_dateMess: "",
                      });
                    }
                  }}
                  errorMess={data?.manu_dateMess}
                />
              }
              lable={"Manufacturing Date "}
            />
            <TableAddProd
              item={
                <SelectImage
                  filename={data?.file?.name}
                  onChange={(e) => {
                    setData({ ...data, file: e.target.files[0], fileMess: "" });
                  }}
                  errorMess={data?.fileMess}
                />
              }
              lable={"Add Product Image"}
            />
          </Table1>
          <Center mt={5}>
            {edit ? (
              <CustomButton
                title={"Update"}
                onClick={add_items}
                loading={loading}
              />
            ) : (
              <CustomButton
                title={"Submit"}
                onClick={add_items}
                loading={loading}
              />
            )}
          </Center>
        </Container>
      </CustomModal>
      <CustomPopup
        isOpen={isPopOpen}
        onClick={delete_items}
        b_name={"Delete"}
        onClose={onPopClose}
        title={"Delete"}
        mess={"Are you sure? You want to Delete Item."}
      />
    </Container>
  );
};

export default SuppliersMain;
