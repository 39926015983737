import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const mobile = localStorage.getItem("mobile");

// const user = JSON.parse(loggedInUser);
export const get_home_data = createAsyncThunk("data", async () => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "category.php",
    { method: "POST" }
  );
  const res = await responce.json();

  return res;
});

export const get_services_home_data = createAsyncThunk(
  "service_home_data",
  async (mobile) => {
    const body = new FormData();
    body.append("Mobile_Number", mobile);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "supplier_home.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();

    return res;
  }
);
export const get_category = createAsyncThunk("cat", async (body) => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "all_category.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();

  return res;
});
export const get_profile = createAsyncThunk("profile", async (body) => {
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "profile.php", {
    method: "POST",
    body: body?.body,
  });
  const res = await responce.json();

  return {...res,data_type:body?.data_type || ""};
});
export const get_partner_profile = createAsyncThunk(
  "profile2",
  async (body) => {
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();

    return res;
  }
);
export const get_gallery = createAsyncThunk("gallery", async () => {
  const body = new FormData();
  body.append("action", "list");
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "Gallery.php", {
    method: "POST",
    body: body,
  });
  const res = await responce.json();
  return res;
});
export const get_events = createAsyncThunk("events", async (body) => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "landscape.php",
    { method: "POST" }
  );
  const res = await responce.json();
  return res;
});
export const get_blogs = createAsyncThunk("blog", async (body) => {
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "blogs.php", {
    method: "POST",
    body: body,
  });
  const res = await responce.json();
  return res;
});
export const get_downloads = createAsyncThunk("download", async () => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Knowledge.php",
    { method: "POST" }
  );
  const res = await responce.json();
  return res;
});

export const get_about_us = createAsyncThunk("about", async (body) => {
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "about.php", {
    method: "POST",
  });
  const res = await responce.json();

  return res;
});
export const get_messaging_list = createAsyncThunk("messaging", async () => {
  const body = new FormData();
  body.append("type", 0);
  body.append("SM", mobile);
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "messaging.php",
    {
      method: "POST",
      body: body,
    }
  );
  const res = await responce.json();
  return res;
});

export const get_notification_list = createAsyncThunk(
  "notification_list",
  async () => {
    const mobile = localStorage.getItem("mobile");
    const body = new FormData();
    // body.append("Mobile_Number", "8965945610")
    body.append("Mobile_Number", mobile);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "notification_list.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_supplier_profile = createAsyncThunk("profile1", async () => {
  const mobile = localStorage.getItem("mobile");
  const body = new FormData();
  // body.append("Mobile_Number", "8965945610")
  body.append("Mobile_Number", mobile);
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "notification_list.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});

export const get_consumable_list = createAsyncThunk(
  "consumable_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "productBySubCategory");
    body.append("sub_category_id", data?.sub_cat_id);
    body.append("service_user_id", data?.userid);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Lets_shop.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_consumable_product_list = createAsyncThunk(
  "consumable_product_list",
  async (id) => {
    const body = new FormData();
    body.append("action", "productCategorylist");
    body.append("sub_category_id", id);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Lets_shop.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_consumable_order_list = createAsyncThunk(
  "consumable_order_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "viewOrder");
    body.append("service_user_id", data?.userid);
    // body.append("product_cat", data?.userid);
    body.append("sub_category_id", data?.sub_cat);
    body.append("service_id", data?.service_id);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Lets_shop.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_member_product_list = createAsyncThunk(
  "member_product_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "viewOrder");
    body.append("service_user_id", data?.userid);
    // body.append("product_cat", data?.userid);
    body.append("sub_category_id", data?.sub_cat_id);
    body.append("service_id", data?.service_id);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Lets_shop.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_mechanic_notification_list = createAsyncThunk(
  "mechanic_notification_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "MemberRequestlist");
    body.append("service_user_id", data?.userid);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Mechanic.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_mechanic_order_list = createAsyncThunk(
  "mechanic_order_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "Orderlist");
    body.append("user_id", data?.userid);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Mechanic.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_insu_mem_view_list = createAsyncThunk(
  "insu_mem_view_list",
  async (data) => {
    const body = new FormData();
    // quotation_list(user_id)
    body.append("action", "quotation_list");
    body.append("user_id", data?.userid);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Insurance.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_insu_sup_send_list = createAsyncThunk(
  "insu_sup_send_list",
  async (data) => {
    const body = new FormData();
    body.append("action", data?.action);
    body.append("category_id", data?.category_id);
    body.append("sub_category_id", data?.sub_category_id);
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Insurance.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_insu_sup_sent_list = createAsyncThunk(
  "insu_sup_sent_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "supplierQuotations");
    body.append("supplier_id", data?.userid);

    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Insurance.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_insu_mem_order_list = createAsyncThunk(
  "insu_mem_order_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "memberorder");
    body.append("user_id", data?.userid);

    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Insurance.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

export const get_insu_sup_order_list = createAsyncThunk(
  "insu_sup_order_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "supplierorder");
    body.append("supplier_id", data?.userid);

    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Insurance.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);

const initialState = {
  home_data: [],
  home_loading: false,
  categoty_data: [],
  cat_loading: false,
  profile_data: [],
  qr_image: "",
  profile_loading: false,
  partner_data: [],
  partner_loading: false,
  gallery_data: [],
  gallery_loading: false,
  about_data: [],
  about_loading: false,
  notification_list: [],
  notification_count: "",
  notification_loading: false,
  events_list: [],
  events_loading: false,
  blogs_list: [],
  blogs_loading: false,
  messaging_list: [],
  messaging_loading: false,
  download_list: [],
  download_loading: false,
  supplier_profile_list: [],
  supplier_profile_loading: false,
  consumable_list: [],
  consumable_product_list: [],
  consumable_loading: false,
  consumable_order_list: [],
  consumable_order_loading: false,
  member_consumable_list: [],
  member_consumable_loading: false,
  supplier_mechanic_notification_list: [],
  supplier_mechanic_notification_loading: false,
  member_mech_order_list: [],
  member_mech_order_loading: [],
  insu_mem_view_list: [],
  insu_mem_view_loading: [],
  insu_sup_send_list: [],
  insu_sup_send_loading: [],
  insu_sup_sent_list: [],
  insu_sup_sent_loading: [],

  insu_mem_order_list: [],
  insu_mem_order_loading: [],

  insu_sup_order_list: [],
  insu_sup_order_loading: [],
};

const dataSlice = createSlice({
  name: "p",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(get_home_data.pending, (state, action) => {
      state.home_loading = true;
    });
    builder.addCase(get_home_data.rejected, (state, action) => {
      state.home_loading = false;
    });
    builder.addCase(get_home_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_loading = false;
      if (data.success) {
        state.home_data = data.categorylist;
      } else {
        state.home_data = [];
      }
    });

    builder.addCase(get_services_home_data.pending, (state, action) => {
      state.home_loading = true;
    });
    builder.addCase(get_services_home_data.rejected, (state, action) => {
      state.home_loading = false;
    });
    builder.addCase(get_services_home_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_loading = false;
      if (data.success) {
        state.home_data = data.data;
      } else {
        state.home_data = [];
      }
    });

    builder.addCase(get_category.pending, (state, action) => {
      state.cat_loading = true;
    });
    builder.addCase(get_category.rejected, (state, action) => {
      state.cat_loading = false;
    });
    builder.addCase(get_category.fulfilled, (state, action) => {
      const data = action.payload;
      state.cat_loading = false;
      if (data.success) {
        state.categoty_data = data.data;
      } else {
        state.categoty_data = [];
      }
    });

    builder.addCase(get_profile.pending, (state, action) => {
      state.profile_loading = true;
    });
    builder.addCase(get_profile.rejected, (state, action) => {
      state.profile_loading = false;
    });
    builder.addCase(get_profile.fulfilled, (state, action) => {
      const data = action.payload;

      console.log("data ===", data);

      state.profile_loading = false;
      if (data.success) {
        state.profile_data = data.message;
        if(data.data_type == 1){
        state.qr_image = data.message?.[0]?.qrcode || "";
        }
      } else {
        state.profile_data = [];
      }
    });

    builder.addCase(get_messaging_list.pending, (state, action) => {
      state.messaging_loading = true;
    });
    builder.addCase(get_messaging_list.rejected, (state, action) => {
      state.messaging_loading = false;
    });
    builder.addCase(get_messaging_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.messaging_loading = false;
      if (data.success) {
        state.messaging_list = data.data;
      } else {
        state.messaging_list = [];
      }
    });

    builder.addCase(get_partner_profile.pending, (state, action) => {
      state.partner_loading = true;
    });
    builder.addCase(get_partner_profile.rejected, (state, action) => {
      state.partner_loading = false;
    });
    builder.addCase(get_partner_profile.fulfilled, (state, action) => {
      const data = action.payload;
      state.partner_loading = false;
      if (data.success) {
        state.partner_data = data.message;
      } else {
        state.partner_data = [];
      }
    });

    builder.addCase(get_gallery.pending, (state, action) => {
      state.gallery_loading = true;
    });
    builder.addCase(get_gallery.rejected, (state, action) => {
      state.gallery_loading = false;
    });
    builder.addCase(get_gallery.fulfilled, (state, action) => {
      const data = action.payload;
      state.gallery_loading = false;
      if (data.success) {
        state.gallery_data = data.data;
      } else {
        state.gallery_data = [];
      }
    });

    builder.addCase(get_about_us.pending, (state, action) => {
      state.about_loading = true;
    });
    builder.addCase(get_about_us.rejected, (state, action) => {
      state.about_loading = false;
    });
    builder.addCase(get_about_us.fulfilled, (state, action) => {
      const data = action.payload;
      state.about_loading = false;
      if (data.success) {
        state.about_data = data.data;
      } else {
        state.about_data = [];
      }
    });

    builder.addCase(get_notification_list.pending, (state, action) => {
      state.notification_loading = true;
    });
    builder.addCase(get_notification_list.rejected, (state, action) => {
      state.notification_loading = false;
    });
    builder.addCase(get_notification_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.notification_loading = false;
      if (data.success) {
        state.notification_list = data.data;
        state.notification_count = data.unread_notification;
      } else {
        state.notification_list = [];
        state.notification_count = "";
      }
    });

    builder.addCase(get_events.pending, (state, action) => {
      state.events_loading = true;
    });
    builder.addCase(get_events.rejected, (state, action) => {
      state.events_loading = false;
    });
    builder.addCase(get_events.fulfilled, (state, action) => {
      const data = action.payload;
      state.events_loading = false;
      if (data.success) {
        state.events_list = data.data;
      } else {
        state.events_list = [];
      }
    });

    builder.addCase(get_blogs.pending, (state, action) => {
      state.blogs_loading = true;
    });
    builder.addCase(get_blogs.rejected, (state, action) => {
      state.blogs_loading = false;
    });
    builder.addCase(get_blogs.fulfilled, (state, action) => {
      const data = action.payload;
      state.blogs_loading = false;
      if (data.success) {
        state.blogs_list = data.data;
      } else {
        state.blogs_list = [];
      }
    });

    builder.addCase(get_downloads.pending, (state, action) => {
      state.download_loading = true;
    });
    builder.addCase(get_downloads.rejected, (state, action) => {
      state.download_loading = false;
    });
    builder.addCase(get_downloads.fulfilled, (state, action) => {
      const data = action.payload;
      state.download_loading = false;
      if (data.success) {
        state.download_list = data.knowledge;
      } else {
        state.download_list = [];
      }
    });

    builder.addCase(get_consumable_list.pending, (state, action) => {
      state.consumable_loading = true;
    });
    builder.addCase(get_consumable_list.rejected, (state, action) => {
      state.consumable_loading = false;
    });
    builder.addCase(get_consumable_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.consumable_loading = false;
      if (data.success) {
        state.consumable_list = data.data;
      } else {
        state.consumable_list = [];
      }
    });

    builder.addCase(get_consumable_product_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.consumable_product_list = data.data;
      } else {
        state.consumable_product_list = [];
      }
    });

    builder.addCase(get_consumable_order_list.pending, (state, action) => {
      state.consumable_order_loading = true;
    });
    builder.addCase(get_consumable_order_list.rejected, (state, action) => {
      state.consumable_order_loading = false;
    });
    builder.addCase(get_consumable_order_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.consumable_order_loading = false;
      if (data.success) {
        state.consumable_order_list = data.data;
      } else {
        state.consumable_order_list = [];
      }
    });

    builder.addCase(get_member_product_list.pending, (state, action) => {
      state.member_consumable_loading = true;
    });
    builder.addCase(get_member_product_list.rejected, (state, action) => {
      state.member_consumable_loading = false;
    });
    builder.addCase(get_member_product_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.member_consumable_loading = false;
      if (data.success) {
        state.member_consumable_list = data.data;
      } else {
        state.member_consumable_list = [];
      }
    });

    builder.addCase(get_mechanic_notification_list.pending, (state, action) => {
      state.supplier_mechanic_notification_loading = true;
    });
    builder.addCase(
      get_mechanic_notification_list.rejected,
      (state, action) => {
        state.supplier_mechanic_notification_loading = false;
      }
    );
    builder.addCase(
      get_mechanic_notification_list.fulfilled,
      (state, action) => {
        const data = action.payload;
        state.supplier_mechanic_notification_loading = false;
        if (data.success) {
          state.supplier_mechanic_notification_list = data.list;
        } else {
          state.supplier_mechanic_notification_list = [];
        }
      }
    );

    builder.addCase(get_mechanic_order_list.pending, (state, action) => {
      state.member_mech_order_loading = true;
    });
    builder.addCase(get_mechanic_order_list.rejected, (state, action) => {
      state.member_mech_order_loading = false;
    });
    builder.addCase(get_mechanic_order_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.member_mech_order_loading = false;
      if (data.success) {
        state.member_mech_order_list = data.list;
      } else {
        state.member_mech_order_list = [];
      }
    });

    builder.addCase(get_insu_mem_view_list.pending, (state, action) => {
      state.insu_mem_view_loading = true;
    });
    builder.addCase(get_insu_mem_view_list.rejected, (state, action) => {
      state.insu_mem_view_loading = false;
    });
    builder.addCase(get_insu_mem_view_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.insu_mem_view_loading = false;
      if (data.success) {
        state.insu_mem_view_list = data.data;
      } else {
        state.insu_mem_view_list = [];
      }
    });

    builder.addCase(get_insu_sup_send_list.pending, (state, action) => {
      state.insu_sup_send_loading = true;
    });
    builder.addCase(get_insu_sup_send_list.rejected, (state, action) => {
      state.insu_sup_send_loading = false;
    });
    builder.addCase(get_insu_sup_send_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.insu_sup_send_loading = false;
      if (data.success) {
        state.insu_sup_send_list = data.data;
      } else {
        state.insu_sup_send_list = [];
      }
    });

    builder.addCase(get_insu_sup_sent_list.pending, (state, action) => {
      state.insu_sup_sent_loading = true;
    });
    builder.addCase(get_insu_sup_sent_list.rejected, (state, action) => {
      state.insu_sup_sent_loading = false;
    });
    builder.addCase(get_insu_sup_sent_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.insu_sup_sent_loading = false;
      if (data.success) {
        state.insu_sup_sent_list = data.data;
      } else {
        state.insu_sup_sent_list = [];
      }
    });

    builder.addCase(get_insu_mem_order_list.pending, (state, action) => {
      state.insu_mem_order_loading = true;
    });
    builder.addCase(get_insu_mem_order_list.rejected, (state, action) => {
      state.insu_mem_order_loading = false;
    });
    builder.addCase(get_insu_mem_order_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.insu_mem_order_loading = false;
      if (data.success) {
        state.insu_mem_order_list = data.data;
      } else {
        state.insu_mem_order_list = [];
      }
    });

    builder.addCase(get_insu_sup_order_list.pending, (state, action) => {
      state.insu_sup_order_loading = true;
    });
    builder.addCase(get_insu_sup_order_list.rejected, (state, action) => {
      state.insu_sup_order_loading = false;
    });
    builder.addCase(get_insu_sup_order_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.insu_sup_order_loading = false;
      if (data.success) {
        state.insu_sup_order_list = data.data;
      } else {
        state.insu_sup_order_list = [];
      }
    });
  },
});

export const { reset } = dataSlice.actions;
export default dataSlice.reducer;
