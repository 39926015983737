import { toast } from "react-toastify";

export const downloadFile = (
  filePath,
  message = "File Downloaded Successfully"
) => {
  console.log("filePath ===",filePath);
  
  const link = document.createElement("a");
  link.href = filePath;
  link.target = "_blank"; 
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  toast.success(message);
};
